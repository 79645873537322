<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Hogyan növelheted az önbizalmadat okostelefonok és digitális eszközök segítségével?
          </h2>
          <p class="hashText">- Az önbizalom kulcsfontosságú </p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">

                <p class="text">
                Az önbizalom kulcsfontosságú ahhoz, hogy sikeresen navigáljunk az élet kihívásai között, és az online térben való navigálás sem kivétel. Az okostelefonok és digitális eszközök rengeteg lehetőséget kínálnak, hogy támogassanak minket az önbizalmunk növelésében és az egészséges önértékelés kialakításában. Célunk, hogy segítsük a fiatalokat, hogy megtalálják a megfelelő eszközöket és technikákat, amikkel erősíthetik önbizalmukat.</p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/onbizalom.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
            <b>Tippek az önbizalom növelésére digitális eszközökkel:</b>
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b> Használj önfejlesztő alkalmazásokat: </b> Az okostelefonok rengeteg olyan applikációt kínálnak, amelyek segíthetnek a személyes fejlődésben. Próbáld ki azokat, amelyek a meditációt, a naplózást vagy az önismeretet segítik, hogy megerősíthesd önbizalmadat. Sok esetben hangsúlyozzuk, hogy az önbizalom növeléséhez szükséges első lépés a pozitív gondolkodás.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Tudd meg, hogyan kommunikálhatsz magabiztosan: </b> Az okostelefonokon számos kommunikációs eszköz áll rendelkezésünkre, hogy fejlesszük verbális és non-verbális kommunikációnkat. A videóhívások, a közösségi médiás üzenetváltások és az interaktív platformok mind segíthetnek abban, hogy bizalmat építsünk másokkal való kapcsolattartásban.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Tudd, mikor van szükséged szünetre: </b>Az önbizalom növelése nemcsak az aktív cselekvésről szól, hanem a pihenésről is. Az okostelefonok segítségével beállíthatunk napirendi időpontokat a pihenésre és a feltöltődésre, amelyek segítenek abban, hogy megőrizzük energiánkat és önbizalmunkat.</p>
                </li>
              </ul>
            </p>
            <p class="text">A digitális világ rengeteg eszközt kínál, hogy támogassuk önbizalmunkat. Ha tudatosan használjuk ezeket, és figyelmet fordítunk a mentális jólétünkre, könnyebben érezhetjük magunkat magabiztosnak és kiegyensúlyozottnak, akár az online, akár a való világban.</p>
            </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="true"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Hogyan növelheted az önbizalmadat okostelefonok és digitális eszközök segítségével?", 
        desc: "Az önbizalom kulcsfontosságú ahhoz, hogy sikeresen navigáljunk az élet kihívásai között, és az online térben való navigálás sem kivétel. ", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Hogyan növelheted az önbizalmadat okostelefonok és digitális eszközök segítségével?",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Az önbizalom kulcsfontosságú ahhoz, hogy sikeresen navigáljunk az élet kihívásai között, és az online térben való navigálás sem kivétel. "        
    })
  }
}
</script>